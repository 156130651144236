import { Buffer } from 'buffer';
window.Buffer = Buffer;  // Aggiungiamo il supporto a Buffer

import React from "react";
import { createRoot } from "react-dom/client";  // Utilizziamo createRoot per React 18
import App from "./App";  // Importiamo il componente App
import { TonConnectUIProvider } from "@tonconnect/ui-react";

// URL del manifest
const manifestUrl = "https://copper-characteristic-galliform-654.mypinata.cloud/ipfs/QmPV6gjwXwuqEbqiZi6bcqPtRr9v1bJhnbKwtKxRfcQx3e";

// Creiamo il root e renderizziamo l'app
const rootElement = document.getElementById("app");
const root = createRoot(rootElement);
root.render(
  <TonConnectUIProvider manifestUrl={manifestUrl}>
    <App />
  </TonConnectUIProvider>
);

// Funzione per connettere il wallet chiamata da Unity
window.connectTONWallet = async () => {
  console.log("Unity ha richiesto la connessione al wallet.");
  if (window.walletHandler) {
    await window.walletHandler();  // Simula la connessione
  } else {
    console.error("Handler di connessione non trovato.");
  }
};

// Funzione per disconnettere il wallet chiamata da Unity
window.disconnectTONWallet = async () => {
  console.log("Unity ha richiesto la disconnessione dal wallet.");
  if (window.walletDisconnectHandler) {
    await window.walletDisconnectHandler();  // Simula la disconnessione
  } else {
    console.error("Handler di disconnessione non trovato.");
  }
};