import React, { useState, useEffect, useCallback } from "react";
import { useTonConnectUI } from "@tonconnect/ui-react";

// Gestisce la connessione con Unity
function waitForUnityInstance(callback) {
    const interval = setInterval(() => {
        if (window.unityInstance && window.isUnityLoaded) {
            console.log("[JS] Unity è pronto e unityInstance è definito.");
            clearInterval(interval); // Ferma il controllo
            callback(); // Esegui la callback quando Unity è pronto
        } else {
            console.log("[JS] In attesa che Unity sia pronto...");
        }
    }, 100); // Controlla ogni 100ms
}

// Funzione per notificare Unity sullo stato del wallet
const notifyUnityWalletStatus = (isConnected) => {
    const statusToSend = isConnected ? "true" : "false";
    console.log(`[JS] Tentativo di invio stato wallet a Unity: ${statusToSend}`);

    if (window.isUnityLoaded && window.unityInstance) {
        console.log("[JS] Unity è pronto. Invio messaggio a Unity.");
        window.unityInstance.SendMessage(
            "GameManager", // Nome dell'oggetto Unity
            "CheckWallet", // Nome del metodo Unity
            statusToSend   // Valore inviato
        );
        console.log("[JS] Stato wallet inviato con successo a Unity.");
    } else {
        console.warn("[JS] Unity non è ancora pronto. Avvio waitForUnityInstance.");
        waitForUnityInstance(() => {
            console.log("[JS] Unity ora è pronto. Invio messaggio a Unity.");
            window.unityInstance.SendMessage(
                "GameManager",
                "CheckWallet",
                statusToSend
            );
            console.log("[JS] Stato wallet inviato con successo a Unity dopo attesa.");
        });
    }
};

// Esportiamo la funzione al contesto globale
window.notifyUnityWalletStatus = notifyUnityWalletStatus;

export default function App() {
    const [tonConnectUI] = useTonConnectUI();
    const [tonWalletAddress, setTonWalletAddress] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Gestisce la connessione del wallet
    const handleWalletConnection = useCallback((address) => {
        setTonWalletAddress(address);
        console.log("Wallet successfully connected:", address);

        // Notify Unity about the wallet connection
        if (window.unityInstance) {
            window.unityInstance.SendMessage("GameManager", "HandleWalletConnection", address);
        }

        // Notifica Unity anche lo stato generale (aggiunto per uniformità)
        notifyUnityWalletStatus(true);
    }, []);

    // Gestisce la disconnessione del wallet
    const handleWalletDisconnection = useCallback(() => {
        setTonWalletAddress(null);
        console.log("Wallet successfully disconnected.");

        // Notifica Unity della disconnessione
        if (window.unityInstance) {
            window.unityInstance.SendMessage("GameManager", "HandleWalletDisconnection", "false");
        }

        // Notifica Unity anche lo stato generale (aggiunto per uniformità)
        notifyUnityWalletStatus(false);
    }, []);

    // Controlla lo stato iniziale del wallet e ascolta i cambiamenti
    useEffect(() => {
        if (tonConnectUI.account?.address) {
            handleWalletConnection(tonConnectUI.account.address);
        } else {
            handleWalletDisconnection();
        }

        const unsubscribe = tonConnectUI.onStatusChange((wallet) => {
            if (wallet) {
                handleWalletConnection(wallet.account.address);
            } else {
                handleWalletDisconnection();
            }
        });

        return () => {
            unsubscribe();
        };
    }, [tonConnectUI, handleWalletConnection, handleWalletDisconnection]);

    // Funzione per connettere il wallet
    const connectTONWallet = async () => {
        setIsLoading(true);
        try {
            console.log("Controllo stato del wallet...");
            console.log("Stato attuale tonConnectUI.account:", tonConnectUI.account);
    
            if (!tonConnectUI.account?.address) {
                console.log("Wallet non connesso. Apro il modal...");
                await tonConnectUI.openModal(); // Apre il modal per connettere il wallet
                console.log("Modal aperto per la connessione al wallet.");
            } else {
                console.log("Wallet già connesso:", tonConnectUI.account?.address);
            }
        } catch (error) {
            console.error("Errore durante la connessione al wallet:", error);
        } finally {
            setIsLoading(false);
        }
    };
    

    // Esponi la funzione di connessione/disconnessione per Unity
    useEffect(() => {
        window.walletHandler = connectTONWallet; // Funzione esposta per Unity
    }, [connectTONWallet]);

    // Esponi una funzione per la disconnessione diretta, se richiesta
    useEffect(() => {
        window.walletDisconnectHandler = async () => {
            setIsLoading(true);
            try {
                if (tonConnectUI.connected) {
                    await tonConnectUI.disconnect();
                    console.log("Wallet disconnesso su richiesta di Unity.");
                    handleWalletDisconnection();
                }
            } catch (error) {
                console.error("Errore durante la disconnessione:", error);
            } finally {
                setIsLoading(false);
            }
        };
    }, [tonConnectUI, handleWalletDisconnection]);

  // Function to handle Stars payment
  const generateStarsInvoice = async (amountInUSD, itemCode, userId) => {
    console.log(`Initiating Stars payment of ${amountInUSD} USD for user ${userId}, item ${itemCode}...`);

    const adjustedAmountUSD = parseFloat((amountInUSD * 1.35).toFixed(2));
    const StarsAmount = parseFloat(adjustedAmountUSD * 50);
    const currency = "XTR";

    try {
        const ws = new WebSocket("wss://thelastdwarfsjs-fa83125773c8.herokuapp.com");

ws.onopen = () => {
    console.log("WebSocket connection established.");
};

ws.onmessage = (event) => {
    const data = JSON.parse(event.data);
    console.log("Message from WebSocket:", data);

    if (data.success && data.userId === userId && data.itemCode === itemCode) {
        clearTimeout(timeoutId); // Cancella il timeout

        // Notifica a Unity
        if (window.unityInstance) {
            window.unityInstance.SendMessage(
                "GameManager",
                "OnStarsPaymentCompleted",
                itemCode
            );
            console.log("Payment confirmation sent to Unity.");
        } else {
            console.warn("Unity instance not found. Unable to send payment confirmation.");
        }

        ws.close(); // Chiudi il WebSocket dopo la conferma del pagamento
    }
};

ws.onerror = (error) => {
    console.error("WebSocket error:", error);
    alert("Errore nella connessione WebSocket.");
    ws.close(); // Chiudi il WebSocket in caso di errore
};

ws.onclose = () => {
    console.log("WebSocket connection closed.");
};

// Timeout per la connessione WebSocket
let timeoutId = setTimeout(() => {
    console.warn("WebSocket connection timeout. Closing WebSocket.");
    ws.close();
}, 300000); // Timeout di 5 minuti (300000 ms)


        // Step 1: Generate the invoice
        const response = await fetch("https://thelastdwarfsjs-fa83125773c8.herokuapp.com/stars/generate-invoice", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                userId,
                itemCode,
                currency,
                amountInUSD: StarsAmount,
            }),
        });

        if (!response.ok) {
            const errorText = await response.text();
            console.error(`Failed to generate Stars invoice. Error: ${errorText}`);
            return;
        }

        const data = await response.json();
        const invoiceLink = data.invoiceLink;

        if (invoiceLink) {
            console.log("Stars invoice generated:", invoiceLink);

            // Step 2: Open the Telegram payment interface
            if (window.Telegram && window.Telegram.WebApp) {
                window.Telegram.WebApp.openInvoice(invoiceLink);
            } else {
                window.open(invoiceLink, "_blank");
            }
        } else {
            console.error("Invoice link not found in the response.");
        }
    } catch (error) {
        console.error("Error generating Stars invoice:", error);
    }
};
  
// Function to handle payments in TON, USDT, or Stars
const makePayment = async (amountInUSD, itemCode, currency, userId) => {
    console.log(`Initiating payment of ${amountInUSD} USD for item ${itemCode} in ${currency} for userId ${userId}...`);

    try {
        if (!userId) {
            console.error("Error: userId is required but is undefined or null.");
            return;
        }

        if (currency === "TON") {
            // Ensure the wallet is connected
            if (!tonWalletAddress) {
                console.warn("TON wallet not connected. Attempting to connect...");
                await connectTONWallet();
                if (!tonWalletAddress) {
                    console.error("Wallet connection failed or was cancelled.");
                    return;
                }
            }

            console.log("Wallet connected. Proceeding with TON payment...");
            const transactionResponse = await fetch("https://thelastdwarfsjs-fa83125773c8.herokuapp.com/ton/generate-transaction", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ amountInUSD, itemCode, userId }),
            });

            if (!transactionResponse.ok) {
                const error = await transactionResponse.text();
                throw new Error(`Errore generazione transazione: ${error}`);
            }

            const { transactionData, transactionId } = await transactionResponse.json();
            console.log("Dati transazione ricevuti:", transactionData);

            // Step 2: Invia la transazione tramite TonConnect
            const result = await tonConnectUI.sendTransaction(transactionData);
            console.log("Risultato transazione TON:", result);

            if (!result.boc) {
                throw new Error("BOC mancante nella transazione.");
            }

            // Invia i dati a Unity immediatamente dopo la conferma TON
            console.log("Pagamento confermato tramite TON Connect. Inviando dati a Unity...");
            if (window.unityInstance) {
                window.unityInstance.SendMessage("GameManager", "OnTonPaymentCompleted", itemCode);
            }

            console.log("Invio dei dati della transazione al backend...");
            const ws = new WebSocket("wss://thelastdwarfsjs-fa83125773c8.herokuapp.com");
            let timeoutId;

            ws.onopen = () => {
                console.log("WebSocket connected");
                ws.send(JSON.stringify({ transactionId, boc: result.boc }));

                // Imposta timeout di 5 minuti
                timeoutId = setTimeout(() => {
                    console.warn("Payment confirmation timeout. Closing WebSocket.");
                    ws.close();
                }, 300000); // Timeout 5 minuti (300000 ms)
            };

            ws.onmessage = (event) => {
                const data = JSON.parse(event.data);
                console.log("Messaggio da WebSocket:", data);

                if (data.success) {
                    console.log("Pagamento confermato. Salvataggio riuscito.");
                } else {
                    console.error("Errore durante il salvataggio del pagamento:", data.error);
                    alert("Errore durante il salvataggio del pagamento. Contatta il supporto.");
                }

                // Cancella il timeout se il messaggio viene ricevuto
                clearTimeout(timeoutId);
                ws.close();
            };

            ws.onerror = (error) => {
                console.error("Errore WebSocket:", error);
                alert("Errore durante la connessione WebSocket.");
                clearTimeout(timeoutId);
                ws.close();
            };

            ws.onclose = () => {
                console.log("Connessione WebSocket chiusa.");
                clearTimeout(timeoutId);
            };

        } else if (currency === "Stars") {
            console.log("Initiating Stars payment...");
            await generateStarsInvoice(amountInUSD, itemCode, userId);
        } else {
            console.error("Unsupported currency type:", currency);
        }
    } catch (error) {
        console.error("Errore durante il processo di pagamento:", error.message);
        alert("Si è verificato un errore durante il processo di pagamento. Riprova.");
    }
};


// Expose functions for Unity
useEffect(() => {
    window.connectTONWallet = connectTONWallet;
    window.makePayment = makePayment;
}, [connectTONWallet, makePayment]);

return null; // No UI is displayed
}



























